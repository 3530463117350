<template>
  <div class="CartEmptyState">
    <md-empty-state md-icon="shopping_cart"
                    :md-label="this.emptyState.label"
                    :md-description="this.emptyState.description">
      <md-divider></md-divider>
      <div class="md-layout">
        <p class="md-layout-item md-xlarge-size-100 md-large-size-100 md-medium-size-100 md-small-size-100 md-xsmall-size-100 o-default-body empty-state-info">
          {{ $t('cartStepper.emptyState.info') }}
        </p>
        <div class="md-layout-item md-xlarge-size-50 md-large-size-50 md-medium-size-50 md-small-size-50 md-xsmall-size-100">
          <CardButton route="domain-names"
                      icon="saved_search"
                      :text="this.$t('common.domainSearch.title')"
                      :description=" $t('common.domainSearch.description')" />
        </div>
        <div class="md-layout-item md-xlarge-size-50 md-large-size-50 md-medium-size-50 md-small-size-50 md-xsmall-size-100">
          <CardButton route="auctions"
                      icon="gavel"
                      :text="this.$t('common.domainAuctions.title')"
                      :description=" $t('common.domainAuctions.description')" />
        </div>
        <p class="md-layout-item md-xlarge-size-100 md-large-size-100 md-medium-size-100 md-small-size-100 md-xsmall-size-100 o-default-body empty-state-or">
          {{ $t('common.or') }}
        </p>
        <div class="md-layout-item md-xlarge-size-25 md-large-size-25 md-medium-size-25 md-small-size-25 md-xsmall-size-100"></div>
        <div class="md-layout-item md-xlarge-size-50 md-large-size-50 md-medium-size-50 md-small-size-50 md-xsmall-size-100">
          <CardButton route="/my-account/user-domains"
                      icon="domain_verification"
                      :text="this.$t('common.userDomains.title')"
                      :description=" $t('common.userDomains.description')" />
        </div>
        <div class="md-layout-item md-xlarge-size-25 md-large-size-25 md-medium-size-25 md-small-size-25 md-xsmall-size-100"></div>
      </div>
      <md-divider></md-divider>
      <div class="md-layout">
        <p class="md-layout-item md-xlarge-size-100 md-large-size-100 md-medium-size-100 md-small-size-100 md-xsmall-size-100 o-default-body empty-state-info">
          {{ $t('common.domainTransfer.title') }}
        </p>
        <DomainTransferInput class="transfer-domain-input" />
        <DomainTransferInfo />
      </div>
    </md-empty-state>
  </div> <!-- /.CartEmptyState -->
</template>

<script>
import CardButton from '@/components/util/CardButton';
import DomainTransferInput from '@/components/domain-transfer/DomainTransferInput';
import DomainTransferInfo from '@/components/domain-transfer/DomainTransferInfo';

export default {
  name: 'CartEmptyState',
  components: {
    CardButton,
    DomainTransferInput,
    DomainTransferInfo
  },
  data() {
    return {
      emptyState: {
        label: this.$t('cartStepper.emptyState.label'),
        description: this.$t('cartStepper.emptyState.description')
      },
    };
  }
};
</script>

<style lang="scss">
.CartEmptyState {

  .md-empty-state {
    max-width: 76rem;
    padding: 1.6rem;
  }

  .md-divider {
    margin-top: 6.4rem;
    width: 100%;
  }

  .md-empty-state-icon {
    height: 10rem;
    font-size: 10rem !important;
    width: 10rem;
    @include desktop {
      height: 16rem;
      font-size: 16rem !important;
      width: 16rem;
    }
  }

  .md-empty-state-label {
    font-size: 2rem;
    @include desktop { font-size: 2.6rem; }
  }

  .md-empty-state-description {
    margin-bottom: 0;
  }

  .empty-state-info {
    font-family: $font-family-body-regular;
    margin: 3.2rem 0 1.6rem;
  }

  .CardButton {
    min-height: 20rem;
  }

  .empty-state-or {
    margin-top: 1.6rem;
  }

  .transfer-domain-input {
    margin: 0 auto 3.2rem;
    width: 100%;
    @include tablet { width: calc(100% - 4.8rem); }
  }
}
</style>



