<template>
  <div class="DomainTransferInfo">
    <md-card class="md-layout-item">
      <md-card-header>
        <SectionTitle :titleContent="messages.title"/>
      </md-card-header>

      <md-card-content>
        <div class="o-container-subsection">
          <SectionSubtitle :titleContent="messages.content.subtitle1"/>
          <TextBlockDefault :textContent="messages.content.content1"/>
        </div>

        <div class="o-container-subsection">
          <SectionSubtitle :titleContent="messages.content.subtitle2"/>
          <TextBlockDefault :textContent="messages.content.content2"/>
        </div>

        <div class="o-container-subsection">
          <SectionSubtitle :titleContent="messages.content.subtitle3"/>
          <TextBlockDefault :textContent="messages.content.content3"/>
        </div>

        <div class="o-container-subsection">
          <SectionSubtitle :titleContent="messages.content.subtitle4"/>
          <TextBlockDefault :textContent="messages.content.content4"/>
        </div>

        <div class="o-container-subsection">
          <SectionSubtitle :titleContent="messages.content.subtitle5"/>
          <TextBlockDefault :textContent="messages.content.content5"/>
        </div>
      </md-card-content>
    </md-card>
  </div> <!-- /.DomainTransferInfo -->
</template>

<script>
import SectionTitle from '@/components/util/SectionTitle';
import SectionSubtitle from '@/components/util/SectionSubtitle';
import TextBlockDefault from '@/components/util/TextBlockDefault';

export default {
  name: 'DomainTransferInfo',
  components: {
    SectionTitle,
    SectionSubtitle,
    TextBlockDefault
  },
  data() {
    return {
      messages: {
        title: this.$t('domainTransferInfo.title'),
        content: {
          subtitle1: this.$t('domainTransferInfo.content.subtitle1'),
          content1: this.$t('domainTransferInfo.content.content1'),
          subtitle2: this.$t('domainTransferInfo.content.subtitle2'),
          content2: this.$t('domainTransferInfo.content.content2'),
          subtitle3: this.$t('domainTransferInfo.content.subtitle3'),
          content3: this.$t('domainTransferInfo.content.content3'),
          subtitle4: this.$t('domainTransferInfo.content.subtitle4'),
          content4: this.$t('domainTransferInfo.content.content4'),
          subtitle5: this.$t('domainTransferInfo.content.subtitle5'),
          content5: this.$t('domainTransferInfo.content.content5')
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.DomainTransferInfo {

  .md-card-content {
    margin-top: 1.6rem;
  }
}
</style>
