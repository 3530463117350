<template>
  <div class="CartView">
    <div class="my-cart-view-container">
      <CartStepper />
    </div>
  </div> <!-- /.CartView -->
</template>

<script>
import store from '@/store';
import {
  FETCH_CART,
  FETCH_USER_DETAILS_ALL
} from '@/store/actions.type';
import {
//   RESET_STATE
  SET_LOADER_START
} from '@/store/mutations.type';
import CartStepper from '@/components/cart/CartStepper';

export default {
  name: 'CartView',
  components: {
    CartStepper
  },
  async beforeRouteEnter(to, from, next) {
    // Await store.commit(RESET_STATE);
    // Start the loader, that is stopped in cart.module, after the data have been fetched
    await store.commit(SET_LOADER_START, true);
    await store.dispatch(FETCH_CART);

    await store.dispatch(FETCH_USER_DETAILS_ALL);
    return next();
  },
};
</script>