<template>
  <div class="CartStepper">
    <div class="o-container-gray">
      <div v-if="this.entries">
        <md-steppers md-sync-route
                     md-alternative
                     md-dynamic-height>
          <md-step v-for="step in this.steps"
                   :key="step.id"
                   :id="step.id"
                   :md-label="step.text"
                   :to="step.route"
                   exact>
            <router-view v-if="routePath === step.route"></router-view>
          </md-step>
        </md-steppers>
      </div>
      <CartEmptyState v-else />
    </div>
  </div> <!-- /.CartStepper -->
</template>

<script>
import {
  mapGetters
} from 'vuex';
import CartEmptyState from '@/components/cart/CartEmptyState';

export default {
  name: 'CartStepper',
  components: {
    CartEmptyState
  },
  data() {
    return {
      steps: [
        {
          id: 'step-items',
          route: '/cart',
          text: this.$t('cartStepper.items'),
        },
        {
          id: 'step-billing',
          route: '/cart/billing',
          text: this.$t('cartStepper.billing'),
        },
        {
          id: 'step-summary',
          route: '/cart/summary',
          text: this.$t('cartStepper.summary'),
        },
      ],
      routePath: null
    };
  },
  computed: {
    ...mapGetters([
      'entries',
    ])
  },
  watch: {
    '$route.path': 'currentRoute'
  },
  mounted() {
    this.currentRoute();
  },
  methods: {
    currentRoute() {
      this.routePath = this.$route.path;
    },
  },
};
</script>

<style lang="scss">
.CartStepper {
  .o-container-gray {
    padding: 2rem 2rem 1.6rem;
  }

  .md-steppers-navigation {
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);
    position: relative;
    z-index: 1;
  }

  .md-stepper-number {
    border-radius: 50%;
    font-size: 2rem;
    height: 5rem;
    padding-top: 1.2rem;
    width: 5rem;
    @include desktop {
      border-radius: 50%;
      font-size: 2.6rem;
      height: 6rem;
      padding-top: 1.7rem;
      width: 6rem;
    }
  }

  // HR for cart stepper
  .md-steppers.md-horizontal.md-alternative .md-stepper-header .md-stepper-number:before {
    right: 6rem;
    @include desktop {
      right: 7rem;
    }
  }

  .md-stepper-header.md-active, .md-stepper-header.md-error {
    font-weight: 700;
  }

  .md-steppers-wrapper {
    margin-left: -.4rem;
    margin-right: -.4rem;
  }

  .md-stepper {
    padding: 1.6rem .5rem 0;
  }
}
</style>
